import React, { useEffect } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom'

import { GlobalStyles, theme } from '@ui-library/myslpro'
import { ThemeProvider } from 'styled-components'
import './App.css'

// Datadog
import { datadogRum } from '@datadog/browser-rum'

// Okta
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security } from '@okta/okta-react'
import { useSelector } from 'react-redux'
import { oktaAuthConfig } from './utils/okta'

// Layouts
import GlobalLayout from './containers/Layout/GlobalLayout'
import LoginLayoutRoute from './containers/Layout/LoginLayout'
import PrivateLayoutRoute from './containers/Layout/PrivateLayout'

// Containers
import Error404Page from './containers/404/404'
import CountsList from './containers/Counts/CountsList/CountsList'
import Exports from './containers/Exports/Exports'
import Homepage from './containers/Homepage/Homepage'
import ProductCardsPage from './containers/ProductCards/ProductCards'
import Display from './containers/Products/Display/Display'
import DisplayProposal from './containers/Products/Display/DisplayProposal'
import Emailing from './containers/Products/Emailing/Emailing'
import EmailingProposal from './containers/Products/Emailing/EmailingProposal'
import Expert360 from './containers/Products/Expert360/Expert360'
import Expert360Proposal from './containers/Products/Expert360/Expert360Proposal'
import MasterDisplay from './containers/Products/MasterDisplay/MasterDisplay'
import MasterDisplayProposal from './containers/Products/MasterDisplay/MasterDisplayProposal'
import Performance from './containers/Products/Performance/Performance'
import PerformanceProposal from './containers/Products/Performance/PerformanceProposal'
import VedettePlus from './containers/Products/VedettePlus/VedettePlus'
import VedettePlusProposal from './containers/Products/VedettePlus/VedettePlusProposal'
import UsersList from './containers/Users/UsersList/UsersList'
import UsersProfile from './containers/Users/UsersProfile/UsersProfile'

import BoostList from './containers/Boost/BoostList/BoostList'
import myAdFactory from './theme/myAdFactory'
import { getEnv } from './utils/getEnv'

// Types
import { Market, Role } from './utils/myAdfactoryApi/swaggerApi'

import {
  currentMarketSelector,
  userProfileSelector,
} from './redux/reducers/UserConfig/userConfig.reducer'

// Constants
import BoostSocialImmo from './components/Products/BoostSocialImmo/BoostSocialImmo'
import {
  BOOST,
  BOOST_PRODUITS,
  BOOST_SOCIAL_IMMO_NEW,
  COMPTAGE,
  COMPTAGE_PRODUITS,
  DISPLAY_IMPRESSION_EDIT,
  DISPLAY_IMPRESSION_NEW,
  DISPLAY_IMPRESSION_PREVIEW,
  EMAILING_ALERTE_EDIT,
  EMAILING_ALERTE_NEW,
  EMAILING_ALERTE_PREVIEW,
  EMAILING_OPTIN_EDIT,
  EMAILING_OPTIN_NEW,
  EMAILING_OPTIN_PREVIEW,
  EXPERT_360_EDIT,
  EXPERT_360_NEW,
  EXPERT_360_PREVIEW,
  MASTER_DISPLAY_NEW,
  MASTER_DISPLAY_PREVIEW,
  PERFORMANCE_EDIT,
  PERFORMANCE_NEW,
  PERFORMANCE_PREVIEW,
  PROFILE,
  USERS,
  VEDETTEPLUS_EDIT,
  VEDETTEPLUS_NEW,
  VEDETTEPLUS_PREVIEW,
} from './routes.constants'

const oktaAuth = new OktaAuth(oktaAuthConfig)

const App = (): React.ReactElement => {
  const history = useHistory()
  const userProfile = useSelector(userProfileSelector)

  const currentMarket = useSelector(currentMarketSelector)

  const restoreOriginalUri = (
    _oktaAuth: typeof oktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  // Add project custom variables in GSL's theme used
  const myAdFactoryTheme = {
    ...theme,
    ...{
      global: {
        ...theme.global,
        myAdFactory,
      },
    },
  }

  useEffect(() => {
    const env = getEnv()
    if (env !== 'localhost') {
      // Datadog initialization
      datadogRum.init({
        applicationId: '355bfe10-f481-4b7d-ae30-72ff006a91f2',
        clientToken: 'pub262a645625f6c891b8fe54e18920afce',
        site: 'datadoghq.com',
        service: 'myadfactory_front',
        env: env,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: env === 'production' ? 20 : 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
        silentMultipleInit: true,
      })
      if (env === 'production') {
        datadogRum.startSessionReplayRecording()
      }
    }
  }, [])

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider theme={myAdFactoryTheme}>
        <GlobalStyles />
        <GlobalLayout>
          <Switch>
            <Route exact path="/login/callback" component={LoginCallback} />
            <LoginLayoutRoute path="/login" />
            <PrivateLayoutRoute
              layoutless
              exact
              path="/exports"
              component={Exports}
            />
            <PrivateLayoutRoute
              exact
              path={['/', '/accueil']}
              component={Homepage}
            />
            <PrivateLayoutRoute exact path={COMPTAGE} component={CountsList} />
            <PrivateLayoutRoute
              exact
              path={BOOST}
              onlyFor={[Market.Neuf]}
              isNotAccessible={currentMarket !== Market.Neuf}
              component={BoostList}
            />
            <PrivateLayoutRoute
              exact
              path={BOOST_PRODUITS}
              onlyFor={[Market.Neuf]}
              isNotAccessible={
                currentMarket !== Market.Neuf
              }
              component={ProductCardsPage}
            />
            <PrivateLayoutRoute
              exact
              path={BOOST_SOCIAL_IMMO_NEW}
              onlyFor={[Market.Neuf]}
              isNotAccessible={
                currentMarket !== Market.Neuf 
              }
              component={BoostSocialImmo}
            />
            <PrivateLayoutRoute
              exact
              path={COMPTAGE_PRODUITS}
              component={ProductCardsPage}
            />
            <PrivateLayoutRoute
              // The route is Protected if you're NOT an admin ==> You don't have access
              isNotAccessible={!userProfile.roles?.includes(Role.ROLE_ADMIN)}
              exact
              path={USERS}
              component={UsersList}
            />
            <PrivateLayoutRoute exact path={PROFILE} component={UsersProfile} />
            <PrivateLayoutRoute
              exact
              path={[EXPERT_360_NEW, EXPERT_360_EDIT]}
              component={Expert360}
              onlyFor={[Market.Ancien]}
            />
            <PrivateLayoutRoute
              exact
              path={EXPERT_360_PREVIEW}
              component={Expert360Proposal}
              onlyFor={[Market.Ancien]}
              layoutless
            />
            <PrivateLayoutRoute
              exact
              path={MASTER_DISPLAY_NEW}
              component={MasterDisplay}
              onlyFor={[Market.Ancien]}
            />
            <PrivateLayoutRoute
              exact
              path={MASTER_DISPLAY_PREVIEW}
              component={MasterDisplayProposal}
              onlyFor={[Market.Ancien]}
              layoutless
            />
            <PrivateLayoutRoute
              exact
              path={[
                EMAILING_OPTIN_NEW,
                EMAILING_OPTIN_EDIT,
                EMAILING_ALERTE_NEW,
                EMAILING_ALERTE_EDIT,
              ]}
              component={Emailing}
              onlyFor={[Market.Neuf, Market.Construire]}
            />
            <PrivateLayoutRoute
              exact
              path={[EMAILING_OPTIN_PREVIEW, EMAILING_ALERTE_PREVIEW]}
              component={EmailingProposal}
              onlyFor={[Market.Neuf, Market.Construire]}
              layoutless
            />
            <PrivateLayoutRoute
              exact
              path={[DISPLAY_IMPRESSION_NEW, DISPLAY_IMPRESSION_EDIT]}
              component={Display}
              onlyFor={[
                Market.Neuf,
                Market.Luxe,
                Market.Construire,
                Market.BureauxEtCommerce,
              ]}
            />
            <PrivateLayoutRoute
              exact
              path={DISPLAY_IMPRESSION_PREVIEW}
              component={DisplayProposal}
              onlyFor={[
                Market.Neuf,
                Market.Luxe,
                Market.Construire,
                Market.BureauxEtCommerce,
              ]}
              layoutless
            />
            <PrivateLayoutRoute
              exact
              path={[PERFORMANCE_NEW, PERFORMANCE_EDIT]}
              component={Performance}
              onlyFor={[Market.Neuf]}
            />
            <PrivateLayoutRoute
              exact
              path={PERFORMANCE_PREVIEW}
              component={PerformanceProposal}
              onlyFor={[Market.Neuf]}
              layoutless
            />
            <PrivateLayoutRoute
              exact
              path={[VEDETTEPLUS_NEW, VEDETTEPLUS_EDIT]}
              component={VedettePlus}
              onlyFor={[Market.Neuf]}
            />
            <PrivateLayoutRoute
              exact
              path={VEDETTEPLUS_PREVIEW}
              component={VedettePlusProposal}
              onlyFor={[Market.Neuf]}
              layoutless
            />
            <Route component={Error404Page} />
          </Switch>
        </GlobalLayout>
      </ThemeProvider>
    </Security>
  )
}

const AppWithRouterAccess = withRouter(App)
const RouterApp = () => (
  <Router>
    <AppWithRouterAccess />
  </Router>
)
export default RouterApp
