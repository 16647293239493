import { createAsyncThunk } from '@reduxjs/toolkit'
import { hideBoostNotification } from '../redux/reducers/Page/Boosts/pageBoost.reducer'
import { getMyAdFactoryClass } from '../utils/myAdfactoryApi/getMyAdFactory.utils'
import { NewBuildRequest } from '../utils/myAdfactoryApi/swaggerApi'

export const getClientsNewBuild = createAsyncThunk(
  '/api/Signifi/NewBuild/GetClients',
  async ({ clientId }: { clientId: string }, getState) => {
    const response = await getMyAdFactoryClass(getState).getClients(clientId)
    return response
  }
)

export const getMyBoostNewBuild = createAsyncThunk(
  'newBuild/getMyBoosts',
  async (_: unknown, getState) => {
    const response = await getMyAdFactoryClass(getState).getMyBoosts()
    return response
  }
)

export const getAllBoostNewBuild = createAsyncThunk(
  'newBuild/getAllBoosts',
  async (_: unknown, getState) => {
    const response = await getMyAdFactoryClass(getState).getAllBoosts()
    return response
  }
)

export const getAdsByClient = createAsyncThunk(
  '/api/Signifi/NewBuild/GetAdsByClient',
  async ({ clientId }: { clientId: string }, getState) => {
    const response =
      await getMyAdFactoryClass(getState).getAdsByClient(clientId)
    return response
  }
)

export const recordBoost = createAsyncThunk(
  '/api/Signifi/NewBuild/RecordBoost',
  async (
    {
      programmes,
      clientId,
      clientName,
      nbMaxBoost,
      bsiId,
      downSellDate,
      contractId,
      contractType,
    }: NewBuildRequest,
    getState
  ) => {
    const body: NewBuildRequest = {
      programmes,
      clientId,
      clientName,
      nbMaxBoost,
      bsiId,
      downSellDate,
      contractId,
      contractType,
    }

    setTimeout(() => {
      getState.dispatch(hideBoostNotification())
    }, 5000)

    const response = await getMyAdFactoryClass(getState).recordBoost(body)
    return response
  }
)
