import { Button, Icon } from '@ui-library/core'
import { CloseRegular, ZapRegular } from '@ui-library/icons'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { IPageBoostState } from '../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import { setTabListBoost } from '../../../redux/reducers/UserConfig/userConfig.reducer'
import { BOOST } from '../../../routes.constants'
import { recordBoost } from '../../../services/boostSocialImmoApi'
import {
  ContractType,
  NewBuildAgency,
  NewBuildRequest,
  Programme,
} from '../../../utils/myAdfactoryApi/swaggerApi'
import Overlay from '../Overlay'

export const OverlayBoostPrograms = ({
  closeOverlay,
  programs,
  bsiId,
  downSellDate,
  contractId,
  contractType,
}: {
  closeOverlay: () => void
  programs: Programme[]
  bsiId: number
  downSellDate?: string
  contractId?: number
  contractType?: ContractType
}): React.ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()

  const boostSocialImmoData: NewBuildAgency = JSON.parse(
    sessionStorage.getItem('boostSocialImmoData') ?? '{}'
  )

  const boostSelectedPrograms = () => {
    const record: NewBuildRequest = {
      programmes: programs,
      clientId: boostSocialImmoData.clientId,
      clientName: boostSocialImmoData.clientName,
      nbMaxBoost: boostSocialImmoData.nbMaxBoost,
      bsiId,
      downSellDate,
      contractId,
      contractType,
    }

    dispatch(recordBoost(record)).then(
      async (data: Promise<IPageBoostState>) => {
        const response = await data
        // Redirect to BOOST page if no error
        !response?.error &&
          // set tab to myBoost before navigating to BOOST page
          dispatch(setTabListBoost('myBoost')) &&
          // navigate to BOOST page
          history.push(BOOST)
      }
    )

    closeOverlay()
  }

  return (
    <Overlay closeOverlay={() => closeOverlay()}>
      <div
        data-testid="overlayBoostPrograms"
        className="overlayFirstConnection"
      >
        <div className="wrapperOverlayFirstConnection">
          <Button
            onClick={() => {
              closeOverlay()
            }}
            data-testid="closeIconButton"
            className="closeOverlay"
            kind="tertiary"
            size="medium"
            variant="text"
            tag="button"
          >
            <Icon icon={CloseRegular} />
          </Button>

          <ContentOverlayBoostPrograms>
            <Icon icon={ZapRegular} size="40px" className="binRegular" />
            <h2>Êtes vous certain ?</h2>
            <p>
              Après validation, l'édition des Boost ne sera plus possible pour
              le mois en cours.
            </p>
          </ContentOverlayBoostPrograms>
          <hr />
          <ActionsButtons data-testid="buttonsBoostOverlay">
            <Button
              className="cancelButton"
              kind="secondary"
              onClick={() => {
                closeOverlay()
              }}
              size="medium"
              tag="button"
              variant="normal"
            >
              Annuler
            </Button>
            <Button
              className="boostButton"
              tag="button"
              kind="primary"
              size="medium"
              variant="normal"
              onClick={() => boostSelectedPrograms()}
            >
              Booster
            </Button>
          </ActionsButtons>
        </div>
      </div>
    </Overlay>
  )
}

const ContentOverlayBoostPrograms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  .binRegular {
    color: #ef1b4c;
    margin-bottom: 15px;
  }
  p {
    font-size: 18px;
  }
`

const ActionsButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
