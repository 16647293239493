import { Icon, Tag } from '@ui-library/core'
import { ThreePointsRegular } from '@ui-library/icons'
import React from 'react'
import { useDispatch } from 'react-redux'
import { saveSelectedRow } from '../../../../../redux/reducers/Page/Boosts/pageBoost.reducer'
import { formatDate } from '../../../../../utils/date'
import { BoostBo } from '../../../../../utils/myAdfactoryApi/swaggerApi'

const RcClientContentBoost = ({
  boostBo,
}: {
  boostBo: BoostBo
}): React.ReactElement => (
  <p className="clientName">
    {boostBo.clientName}
    <br />
    <span>{boostBo.clientId}</span>
  </p>
)

const ProductColumnContent = ({
  boostBo,
}: {
  boostBo: BoostBo
}): React.ReactElement => (
  <p>
    {' '}
    Boost Social Immo {`${boostBo.contractType} `}
    {!boostBo.isActiveContract && (
      <>
        <br /> <Tag label="Contrat résilié" kind="inverse" />
      </>
    )}
  </p>
)

export const TableRowBoost = ({
  boostBo,
  setActionButtonsMenuOpen,
}: {
  boostBo: BoostBo
  setActionButtonsMenuOpen: (value: boolean) => void
}): React.ReactElement => {
  const dispatch = useDispatch()

  const handleClickBoostActions = (e: React.MouseEvent<HTMLElement>) => {
    boostBo.isActiveContract && setActionButtonsMenuOpen(true)
    dispatch(
      saveSelectedRow({
        x: e.clientX,
        y: e.clientY,
        clientName: boostBo.clientName,
        clientId: boostBo.clientId,
        nbMaxBoost: boostBo.nbMaxBoost,
      })
    )
  }
  return (
    <tr className="boost">
      <td aria-label="RcClient">
        <div className="tdWrapper">
          <div className="cellText">
            <RcClientContentBoost boostBo={boostBo} />
          </div>
          <span className="divider" />
        </div>
      </td>
      <td aria-label="product">
        <div className="tdWrapper">
          <div className="cellText">
            <ProductColumnContent boostBo={boostBo} />
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">
            {boostBo.programmes?.filter((p) => p.isChecked).length}
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">
            {formatDate(new Date(boostBo.createdDate ?? 0))}
          </div>
          <span className="divider" />
        </div>
      </td>
      <td>
        <div className="tdWrapper">
          <div className="cellText">BSI-{boostBo.bsiId}</div>
        </div>
      </td>
      <td
        data-testid="boostActions"
        onClick={handleClickBoostActions}
        aria-label="Actions"
      >
        {boostBo.isActiveContract && (
          <div data-testid="clickBoostDiv">
            <Icon
              icon={ThreePointsRegular}
              size="16px"
              className="threePointsVertical"
            />
          </div>
        )}
      </td>
    </tr>
  )
}
